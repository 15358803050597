import { addDays } from "date-fns";

const CookieNamePrefix = "norneSecOrderNotification";

export const hasOrderNotificationCookie = (orderNumber: string): boolean => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieName = `${CookieNamePrefix}-${orderNumber}`;
  return decodedCookie.split(";").some(cookie => cookie.trim().startsWith(cookieName));
};

export const setOrderNotificationCookie = (orderNumber: string) => {
  const d = addDays(new Date(), 5);
  const expires = `expires=${d.toUTCString()}`;
  const cookieName = `${CookieNamePrefix}-${orderNumber}`;
  document.cookie = `${cookieName}=${cookieName};${expires};path=/`;
};

export const deleteOrderNotificationCookie = (orderNumber: string) => {
  const cookieName = `${CookieNamePrefix}-${orderNumber}`;
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
