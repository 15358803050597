"use client";

import appInsights from "@portal/appInsights";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "@portal/redux/store";
import UserNotification from "@portal/components/molecules/userNotification";
import { SessionProvider } from "@portal/auth/client/SessionProvider";
import { useEffect } from "react";
import { addSignOutListner, removeSignOutListner } from "@portal/auth/client/api/signOut/signOutBroadcast";

if (typeof window !== "undefined") {
  try {
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  } catch (error) {
    console.error("failed loading app insights", error);
  }
}

const InnerLayout = ({ children }: Readonly<{ children: React.ReactNode }>) => {
  useEffect(() => {
    const handleSignOut = () => {
      // handle sign out in a different tab, make it delayed to allow logout process to finish
      setTimeout(() => {
        window.location.href = "/logout";
      }, 3000);
    };

    addSignOutListner(handleSignOut);
    return () => {
      removeSignOutListner(handleSignOut);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
        <UserNotification />
      </PersistGate>
    </Provider>
  );
};

const RootLayout = ({ children }: Readonly<{ children: React.ReactNode }>) => (
  <SessionProvider>
    <InnerLayout>{children}</InnerLayout>
  </SessionProvider>
);

export default RootLayout;
