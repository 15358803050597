export const broadcastUserNotification = (orderNumber: number, text: string) => {
  try {
    const channel = new BroadcastChannel("user-notification-channel");
    channel.postMessage({ orderNumber, text });
  } catch (e) {
    console.error("broadcast failed, user notification", e);
  }
};

export const addUserNotificationListner = (handler: (event: MessageEvent) => void) => {
  const channel = new BroadcastChannel("user-notification-channel");
  channel.addEventListener("message", event => {
    handler(event);
  });
};

export const removeUserNotificationListner = (handler: (event: MessageEvent) => void) => {
  const channel = new BroadcastChannel("user-notification-channel");
  channel.removeEventListener("message", handler);
};
