import { TextResources } from "@portal/assets/text/TextResources";
import { IInfrontStock } from "@portal/redux/reducers/api/infront/types";
import { IOrderExtended } from "@portal/redux/reducers/api/orders/types";
import { IOrder } from "@portal/types/api/IOrder";
import { IOrderResponse } from "@portal/types/api/IOrderResponse";

export const GetOrderStatus = (order: IOrder) => {
  switch (order.orderStatus) {
    case "B":
      return TextResources.ORDER_ACCEPTED;
    case "M":
      return TextResources.ORDER_MANUAL;
    case "U":
      return TextResources.ORDER_NOT_ACCEPTED;
    case "W":
      return TextResources.ORDER_PENDING;
    case "S":
      return TextResources.ORDER_COMPLETED;
    case "K":
      return TextResources.ORDER_CANCELLED;
    case "F":
      return TextResources.ORDER_FUTURE;
    case "X":
      return TextResources.ORDER_EXPIRED;
    case "V":
      return TextResources.PENDING_MATCH;
    case "E":
      return TextResources.EXTERNAL_COVERAGE;
    case "R":
      return TextResources.ORDER_REQUIRES_APPROVAL;
    default:
      return TextResources.ORDER_UNKNOWN;
  }
};

export const GetStockOrderStatus = (stockOrderStatus?: string) => {
  if (!stockOrderStatus) {
    return TextResources.ORDER_UNKNOWN;
  }
  switch (stockOrderStatus) {
    case "B":
      return TextResources.ORDER_ACCEPTED;
    case "M":
      return TextResources.ORDER_MANUAL;
    case "U":
      return TextResources.ORDER_NOT_ACCEPTED;
    case "W":
      return TextResources.ORDER_PENDING;
    case "S":
      return TextResources.ORDER_COMPLETED;
    case "K":
      return TextResources.ORDER_CANCELLED;
    case "F":
      return TextResources.ORDER_FUTURE;
    case "X":
      return TextResources.ORDER_EXPIRED;
    case "V":
      return TextResources.PENDING_MATCH;
    case "E":
      return TextResources.EXTERNAL_COVERAGE;
    case "R":
      return TextResources.ORDER_REQUIRES_APPROVAL;
    default:
      return TextResources.ORDER_UNKNOWN;
  }
};

export const GetSwitchFundOrderStatus = (fundOrderStatus?: string) => {
  if (!fundOrderStatus) {
    return TextResources.FUND_ORDER_UNKNOWN;
  }
  switch (fundOrderStatus) {
    case "A":
      return TextResources.FUND_ORDER_ACTIVE;
    case "O":
      return TextResources.FUND_ORDER_SETTLED;
    case "V":
      return TextResources.FUND_ORDER_SENT_TO_PAYMENT_AGENT;
    case "U":
      return TextResources.FUND_ORDER_EXECUTED;
    case "X":
      return TextResources.FUND_ORDER_EXPIRED;
    case "S":
      return TextResources.FUND_ORDER_DELETED;
    case "K":
      return TextResources.FUND_ORDER_CANCELLED;
    case "R":
      return TextResources.FUND_ORDER_REJECTED;
    case "C":
      return TextResources.FUND_ORDER_CHANGED;
    case "M":
      return TextResources.FUND_ORDER_REMOVED_FROM_PARENT_ORDER;
    case "P":
      return TextResources.FUND_ORDER_AWAITING_PRICE_FROM_EXTERNAL_FUND_MANAGER;
    case "L":
      return TextResources.FUND_ORDER_DEPENDEND_ON_ANOTHER_ORDER;
    case "D":
      return TextResources.FUND_ORDER_DEACTIVATED;
    case "E":
      return TextResources.FUND_ORDER_EXECUTING_LIQUIDATION_ORDER;
    default:
      return TextResources.FUND_ORDER_UNKNOWN;
  }
};

export const mapToOrderExtended = (order: IOrderResponse, stockData?: IInfrontStock): IOrderExtended => {
  return {
    orderNumber: order.orderNumber,
    portfolioId: order.portfolioId.toString(),
    tickerCode: order.tickerCode,
    orderStatus: order.orderStatus,
    orderLimit: order.priceLimit,
    orderRest: order.quantity ? order.quantity - (order.matchedQuantity ?? 0) : 0,
    orderQuantity: order.quantity,
    orderCreated: order.createdDate,
    orderUpdated: undefined,
    instrumentType: "",
    estimatedAmount: order.orderAmount,
    registeredBy: "",
    orderValue: undefined,
    isin: order.isin,
    instrumentId: order.instrumentId,
    isStopOrder: order.isStopOrder,
    stopLossLimit: order.stopLossLimit,
    stopLossTriggerCriterion: order.stopLossTriggerCriterion,
    statusText: GetStockOrderStatus(order.orderStatus),
    orderType: order.isPurchaseOrder ? "Kjøp" : "Selg",
    dateTo: order.dateTo,
    lastPrice: stockData?.last,
    lastPriceVsLimit:
      stockData && order.priceLimit ? (Math.abs(stockData.last - order.priceLimit) * 100) / order.priceLimit : undefined,
    yesterdayClose: stockData?.yesterdayClose ?? 0,
    yesterdayCloseVsLimit:
      stockData && order.priceLimit
        ? (Math.abs(stockData.yesterdayClose - order.priceLimit) * 100) / order.priceLimit
        : undefined,
  };
};

export const canNotifyOrder = (order: IOrderExtended): boolean => {
  return order?.orderStatus === "B" || order?.orderStatus === "W" || order?.orderStatus === "F" || order?.orderStatus === "M";
};
